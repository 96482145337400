import { waitAuth } from "~/utils/middleware";

import { Sub } from "~/types/subscriptions";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async () => {
	await waitAuth(Sub.Type.ObjectStorage);
	const isCanceled = __subscription().getIsDisabled(Sub.Type.ObjectStorage);
	if (!__subscription().has(Sub.Type.ObjectStorage) && !isCanceled) {
		log("❌ Has no S3");
		return navigateTo("/");
	}

	log("✅ Has S3 or S3 is canceled");
});
